import React, { useState, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { StorySlide } from '../components/StorySlide';
import { ProgressBar } from '../components/ProgressBar';
import { Clock, Music, Headphones, Trophy, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getSpotifyData } from '../services/spotify';
import { SpotifyData } from '../types/spotify';

const SLIDE_DURATION = 5000;

export function Dashboard() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [spotifyData, setSpotifyData] = useState<SpotifyData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const totalSlides = 4;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem('spotify_access_token');
        if (!accessToken) {
          throw new Error('No access token found');
        }
        const data = await getSpotifyData(accessToken);
        setSpotifyData(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch Spotify data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, SLIDE_DURATION);

    return () => clearInterval(timer);
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
    const x = e.clientX - rect.left;
    if (x < rect.width / 2) {
      setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
    } else {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }
  };

  if (loading) {
    return (
      <Layout>
        <div className="h-screen flex items-center justify-center">
          <div className="text-2xl text-white">Loading your music data...</div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="h-screen flex items-center justify-center">
          <div className="text-2xl text-red-500">{error}</div>
        </div>
      </Layout>
    );
  }

  if (!spotifyData) {
    return null;
  }

  return (
    <Layout>
      <div className="h-screen flex items-center justify-center">
        <button 
          onClick={() => navigate('/connect')}
          className="absolute top-4 left-4 z-50 text-white/80 hover:text-white flex items-center gap-2 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          <span>Back</span>
        </button>

        <div className="relative w-full max-w-md h-[80vh] mx-auto overflow-hidden rounded-2xl" onClick={handleClick}>
          <div className="absolute top-4 left-4 right-4 z-50">
            <ProgressBar total={totalSlides} current={currentSlide} />
          </div>

          {/* Minutes Listened Slide */}
          <StorySlide isActive={currentSlide === 0}>
            <div className="h-full bg-gradient-to-br from-purple-600 to-blue-600 flex flex-col items-center justify-center p-8 text-center">
              <Clock className="w-16 h-16 mb-8 animate-pulse" />
              <h2 className="text-4xl font-bold mb-4">Time Well Spent</h2>
              <p className="text-7xl font-bold mb-4">{spotifyData.totalMinutes.toLocaleString()}</p>
              <p className="text-xl opacity-80">minutes of music this year</p>
            </div>
          </StorySlide>

          {/* Top Artists Slide */}
          <StorySlide isActive={currentSlide === 1}>
            <div className="h-full bg-gradient-to-br from-pink-600 to-purple-600 flex flex-col items-center justify-center p-8">
              <Music className="w-16 h-16 mb-8" />
              <h2 className="text-3xl font-bold mb-8">Your Top Artists</h2>
              <div className="space-y-6 w-full">
                {spotifyData.topArtists.map((artist, index) => (
                  <motion.div
                    key={artist.name}
                    className="flex items-center gap-4"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <img src={artist.image} alt={artist.name} className="w-16 h-16 rounded-full object-cover" />
                    <div>
                      <p className="font-bold">{index + 1}. {artist.name}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </StorySlide>

          {/* Top Songs Slide */}
          <StorySlide isActive={currentSlide === 2}>
            <div className="h-full bg-gradient-to-br from-blue-600 to-cyan-600 flex flex-col items-center justify-center p-8">
              <Headphones className="w-16 h-16 mb-8" />
              <h2 className="text-3xl font-bold mb-8">Your Top Songs</h2>
              <div className="space-y-6 w-full">
                {spotifyData.topSongs.map((song, index) => (
                  <motion.div
                    key={song.name}
                    className="flex items-center gap-4"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <img src={song.image} alt={song.name} className="w-16 h-16 rounded-lg object-cover" />
                    <div>
                      <p className="font-bold">{song.name}</p>
                      <p className="text-sm opacity-80">{song.artist}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </StorySlide>

          {/* Genres Slide */}
          <StorySlide isActive={currentSlide === 3}>
            <div className="h-full bg-gradient-to-br from-green-600 to-blue-600 flex flex-col items-center justify-center p-8 text-center">
              <Trophy className="w-16 h-16 mb-8" />
              <h2 className="text-3xl font-bold mb-8">Your Top Genres</h2>
              <div className="flex flex-wrap justify-center gap-4">
                {spotifyData.genres.map((genre, index) => (
                  <motion.div
                    key={genre}
                    className="px-4 py-2 bg-white/20 rounded-full backdrop-blur-sm"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    {genre}
                  </motion.div>
                ))}
              </div>
            </div>
          </StorySlide>
        </div>
      </div>
    </Layout>
  );
} 