import { SpotifyData } from '../types/spotify';

const SPOTIFY_AUTH_URL = 'https://accounts.spotify.com/authorize';
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'https://us-central1-wrapped2024.cloudfunctions.net/api';
const SPOTIFY_API_BASE = 'https://api.spotify.com/v1';

interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

export const spotifyService = {
  getAuthUrl() {
    const params = new URLSearchParams({
      client_id: import.meta.env.VITE_SPOTIFY_CLIENT_ID,
      response_type: 'code',
      redirect_uri: import.meta.env.VITE_SPOTIFY_REDIRECT_URI,
      scope: 'user-read-private user-read-email playlist-read-private user-top-read user-read-recently-played',
    });

    return `${SPOTIFY_AUTH_URL}?${params.toString()}`;
  },

  async getAccessToken(code: string): Promise<TokenResponse> {
    const response = await fetch(`${API_BASE_URL}/spotify/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        redirect_uri: import.meta.env.VITE_SPOTIFY_REDIRECT_URI,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to get access token');
    }

    return response.json();
  },
};

export async function getSpotifyData(accessToken: string): Promise<SpotifyData> {
  try {
    const headers = { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };

    const [topArtists, topTracks, recentlyPlayed] = await Promise.all([
      fetch(`${SPOTIFY_API_BASE}/me/top/artists?limit=3&time_range=short_term`, {
        headers
      }).then(res => {
        if (!res.ok) throw new Error(`Failed to fetch top artists: ${res.status}`);
        return res.json();
      }),
      
      fetch(`${SPOTIFY_API_BASE}/me/top/tracks?limit=3&time_range=short_term`, {
        headers
      }).then(res => {
        if (!res.ok) throw new Error(`Failed to fetch top tracks: ${res.status}`);
        return res.json();
      }),
      
      fetch(`${SPOTIFY_API_BASE}/me/player/recently-played?limit=50`, {
        headers
      }).then(res => {
        if (!res.ok) throw new Error(`Failed to fetch recently played: ${res.status}`);
        return res.json();
      })
    ]);

    const totalMinutes = recentlyPlayed.items.reduce((acc: number, item: any) => {
      return acc + Math.floor(item.track.duration_ms / 60000);
    }, 0);

    const genres = Array.from(new Set(
      topArtists.items.flatMap((artist: any) => artist.genres)
    )).slice(0, 5) as string[];

    return {
      totalMinutes,
      topArtists: topArtists.items.map((artist: any) => ({
        name: artist.name,
        image: artist.images[0]?.url || ''
      })),
      topSongs: topTracks.items.map((track: any) => ({
        name: track.name,
        artist: track.artists[0].name,
        image: track.album.images[0]?.url || ''
      })),
      genres
    };
  } catch (error) {
    console.error('Error fetching Spotify data:', error);
    throw error;
  }
}

export async function getUserTopItems(type: 'artists' | 'tracks', timeRange: string = 'medium_term') {
  const response = await fetch(`https://api.spotify.com/v1/me/top/${type}?limit=3&time_range=${timeRange}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('spotify_access_token')}`
    }
  });
  return response.json();
}

export async function getRecentlyPlayed() {
  const response = await fetch('https://api.spotify.com/v1/me/player/recently-played?limit=50', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('spotify_access_token')}`
    }
  });
  return response.json();
}