import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { GradientButton } from '../components/GradientButton';
import { Instagram, ArrowLeft, Music } from 'lucide-react';

export function SocialConnect() {
  const { platform } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const platformConfig = { 
    instagram: {
      title: 'Connect Instagram',
      icon: <Instagram className="w-12 h-12 text-gradient" />,
      placeholder: 'Enter your Instagram username',
      buttonVariant: 'purple' as const
    },
    spotify: {
      title: 'Connect Spotify',
      icon: <Music className="w-12 h-12 text-gradient" />,
      placeholder: 'Connect with Spotify',
      buttonVariant: 'purple' as const
    }
  };

  const config = platform ? platformConfig[platform as keyof typeof platformConfig] : null;

  if (!config) {
    navigate('/connect');
    return null;
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (platform === 'spotify') {
      const CLIENT_ID = import.meta.env.VITE_SPOTIFY_CLIENT_ID;
      const REDIRECT_URI = `${window.location.origin}/callback`;
      const scope = 'user-top-read user-read-recently-played';
      
      const params = new URLSearchParams({
        client_id: CLIENT_ID,
        response_type: 'code',
        redirect_uri: REDIRECT_URI,
        scope: scope,
      });

      window.location.href = `https://accounts.spotify.com/authorize?${params.toString()}`;
    } else {
      console.log(`Connecting ${platform} account:`, username);
      navigate('/demo');
    }
  };

  return (
    <Layout>
      <div className="h-screen flex items-center justify-center">
        <div className="relative z-10 max-w-md w-full mx-auto px-4">
          <button 
            onClick={() => navigate('/connect')}
            className="absolute -top-16 left-0 text-gray-400 hover:text-white flex items-center gap-2 transition-colors"
          >
            <ArrowLeft className="w-6 h-6" />
            <span>Back</span>
          </button>

          <div className="text-center mb-12">
            <div className="inline-block p-4 rounded-full bg-gradient-to-br from-purple-500/20 to-blue-500/20 backdrop-blur-sm mb-4">
              {config.icon}
            </div>
            <h2 className="text-3xl font-bold mt-4 bg-gradient-to-r from-purple-400 to-blue-400 text-transparent bg-clip-text">
              {config.title}
            </h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-300 mb-2">
                Username
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={config.placeholder}
                className="w-full px-4 py-3 bg-white/10 rounded-lg border border-white/20 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all"
              />
            </div>

            <GradientButton
              variant={config.buttonVariant}
              onClick={handleSubmit}
            >
              Connect Account
            </GradientButton>
          </form>

          <p className="mt-8 text-sm text-gray-400 text-center">
            We'll use this to customize your Wrapped experience
          </p>
        </div>
      </div>
    </Layout>
  );
}