import React from 'react';
import { Link } from 'react-router-dom';

interface GradientButtonProps {
  children: React.ReactNode;
  variant?: 'white' | 'purple' | 'blue';
  icon?: React.ReactNode;
  to?: string;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export function GradientButton({ 
  children, 
  variant = 'white', 
  icon, 
  to, 
  href,
  onClick 
}: GradientButtonProps) {
  const baseClasses = "group relative w-full flex items-center justify-center gap-2 px-6 py-3 rounded-full font-medium transition-all duration-300 hover:scale-105 hover:shadow-lg";
  
  const variantClasses = {
    white: "bg-white text-black hover:bg-opacity-90",
    purple: "bg-gradient-to-r from-purple-600 to-purple-400 text-white",
    blue: "bg-gradient-to-r from-blue-600 to-blue-400 text-white"
  };

  const buttonContent = (
    <>
      <span className="absolute inset-0 rounded-full bg-white/10 opacity-0 group-hover:opacity-100 transition-opacity" />
      <span className="relative flex items-center gap-2">
        {icon && <span className="w-5 h-5">{icon}</span>}
        {children}
      </span>
    </>
  );

  if (to) {
    return (
      <Link to={to} className={`${baseClasses} ${variantClasses[variant]}`}>
        {buttonContent}
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} className={`${baseClasses} ${variantClasses[variant]}`}>
        {buttonContent}
      </a>
    );
  }

  return (
    <button 
      onClick={onClick} 
      className={`${baseClasses} ${variantClasses[variant]}`}
    >
      {buttonContent}
    </button>
  );
}