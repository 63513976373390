import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Connect } from './pages/Connect';
import { SocialConnect } from './pages/SocialConnect';
import { Demo } from './pages/Demo';
import { SpotifyCallback } from './pages/SpotifyCallback';
import { Wrapped } from './pages/Wrapped';
import { Dashboard } from './pages/Dashboard';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/connect/:platform" element={<SocialConnect />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/callback" element={<SpotifyCallback />} />
        <Route path="/wrapped" element={<Wrapped />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}