import React from 'react';
import { motion } from 'framer-motion';

interface StorySlideProps {
  children: React.ReactNode;
  isActive: boolean;
}

export function StorySlide({ children, isActive }: StorySlideProps) {
  return (
    <motion.div
      className="absolute inset-0 w-full h-full"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{
        opacity: isActive ? 1 : 0,
        scale: isActive ? 1 : 0.9,
        pointerEvents: isActive ? 'auto' : 'none',
      }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}