import React from 'react';
import { Layout } from '../components/Layout';
import { GradientButton } from '../components/GradientButton';
import { Music2, Instagram, PlayCircle } from 'lucide-react';

export function Connect() {
  // Replace this with your actual client ID from Spotify Developer Dashboard
  const CLIENT_ID = import.meta.env.VITE_SPOTIFY_CLIENT_ID || "e9c474434faf4cc39186c9a36ced01f0";
  const REDIRECT_URI = `${window.location.origin}/callback`;
  
  const SPOTIFY_AUTH_URL = "https://accounts.spotify.com/authorize";
  const spotifyParams = new URLSearchParams({
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: "code",
    scope: "user-top-read user-read-recently-played"
  });

  return (
    <Layout>
      <div className="h-screen flex items-center justify-center">
        <div className="relative z-10 max-w-md w-full mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center bg-gradient-to-r from-purple-400 to-blue-400 text-transparent bg-clip-text">
            Connect Your Accounts
          </h2>
          
          {CLIENT_ID === "YOUR_CLIENT_ID" ? (
            <div className="mb-4 p-4 bg-yellow-500/10 border border-yellow-500/20 rounded-lg text-yellow-200 text-sm">
              ⚠️ Please set up your Spotify Client ID in the environment variables
            </div>
          ) : null}

          <div className="space-y-4">
            <GradientButton 
              href={`${SPOTIFY_AUTH_URL}?${spotifyParams.toString()}`}
              variant="white"
              icon={<Music2 />}
            >
              Connect with Spotify
            </GradientButton>

            <GradientButton 
              to="/connect/instagram"
              variant="purple"
              icon={<Instagram />}
            >
              Connect Instagram
            </GradientButton>

            <GradientButton 
              to="/demo"
              variant="blue"
              icon={<PlayCircle />}
            >
              View Demo
            </GradientButton>
          </div>

          <p className="mt-8 text-sm text-gray-400 text-center">
            Connect your accounts to generate your personalized Wrapped 2024 experience
          </p>
        </div>
      </div>
    </Layout>
  );
}