import React, { useState, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { StorySlide } from '../components/StorySlide';
import { ProgressBar } from '../components/ProgressBar';
import { Clock, Music, Headphones, Trophy } from 'lucide-react';
import { motion } from 'framer-motion';
import type { SpotifyData } from '../types/spotify';
import { useNavigate } from 'react-router-dom';

const SLIDE_DURATION = 5000;

export function Wrapped() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [data, setData] = useState<SpotifyData | null>(null);
  const totalSlides = 4;
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = sessionStorage.getItem('spotifyData');
    if (!storedData) {
      navigate('/connect');
      return;
    }
    setData(JSON.parse(storedData));
  }, [navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, SLIDE_DURATION);

    return () => clearInterval(timer);
  }, []);

  if (!data) {
    return null;
  }

  // Rest of the component is similar to Demo.tsx but uses real data instead of demoData
  return (
    <Layout>
      <div className="h-screen flex items-center justify-center">
        {/* Same structure as Demo.tsx but using data from Spotify API */}
      </div>
    </Layout>
  );
}