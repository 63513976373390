import React from 'react';
import { Layout } from '../components/Layout';
import { GradientButton } from '../components/GradientButton';
import { Sparkles, Music, Image, TrendingUp } from 'lucide-react';
import { FeatureCard } from '../components/FeatureCard';
import { motion } from 'framer-motion';

export function Home() {
  return (
    <Layout>
      <div className="min-h-screen">
        {/* Hero Section */}
        <div className="relative h-screen flex items-center justify-center overflow-hidden">
          {/* Animated Background Elements */}
          <div className="absolute inset-0 grid-background opacity-20" />
          
          <div className="absolute inset-0">
            {/* Rotating gradient circles */}
            <div className="absolute top-1/4 -left-1/4 w-[800px] h-[800px] animate-rotate opacity-30">
              <div className="absolute inset-0 gradient-blur rounded-full" />
            </div>
            <div className="absolute -bottom-1/4 -right-1/4 w-[600px] h-[600px] animate-rotate opacity-30" 
                 style={{ animationDirection: 'reverse', animationDuration: '25s' }}>
              <div className="absolute inset-0 gradient-blur rounded-full" />
            </div>

            {/* Floating particles */}
            {Array.from({ length: 5 }).map((_, i) => (
              <motion.div
                key={i}
                className="absolute w-2 h-2 bg-white rounded-full"
                initial={{ opacity: 0, scale: 0 }}
                animate={{
                  opacity: [0.5, 1, 0.5],
                  scale: [1, 2, 1],
                  x: ['0%', '100%', '0%'],
                  y: ['0%', '50%', '0%'],
                }}
                transition={{
                  duration: 10,
                  repeat: Infinity,
                  delay: i * 2,
                  ease: "linear"
                }}
                style={{
                  left: `${20 + i * 15}%`,
                  top: `${30 + i * 10}%`,
                }}
              />
            ))}
          </div>

          {/* Main Content */}
          <div className="relative z-10 max-w-2xl mx-auto px-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-7xl font-bold mb-6 text-gradient relative inline-block">
                Wrapped 2024
                <motion.div
                  className="absolute -top-6 -right-6 text-yellow-300"
                  animate={{
                    rotate: [0, 20, 0],
                    scale: [1, 1.2, 1],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <Sparkles className="w-8 h-8" />
                </motion.div>
              </h1>
            </motion.div>

            <motion.p
              className="text-xl text-gray-300 mb-12 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Experience your year in music, social media, and more through an immersive journey of discovery.
            </motion.p>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <GradientButton 
                to="/connect"
                variant="purple"
                icon={<Sparkles className="w-5 h-5" />}
              >
                Start Your Experience
              </GradientButton>
            </motion.div>

            <motion.p
              className="mt-8 text-sm text-gray-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              Connect your accounts to unlock your personalized year in review
            </motion.p>
          </div>

          {/* Scroll Indicator */}
          <motion.div
            className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1,
              delay: 1,
              y: {
                duration: 0.8,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut"
              }
            }}
          >
            <div className="w-6 h-10 border-2 border-white/30 rounded-full flex items-start justify-center p-2">
              <div className="w-1 h-3 bg-white/60 rounded-full animate-pulse-slow" />
            </div>
          </motion.div>
        </div>

        {/* Features Section */}
        <div className="max-w-6xl mx-auto px-4 py-24">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <Music />,
                title: "Music Journey",
                description: "Discover your top artists, songs, and genres that defined your year"
              },
              {
                icon: <Image />,
                title: "Visual Stories",
                description: "Relive your best moments through a curated collection of memories"
              },
              {
                icon: <TrendingUp />,
                title: "Trending Content",
                description: "See how your taste evolved and aligned with global trends"
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <FeatureCard {...feature} />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}