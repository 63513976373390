import React from 'react';
import { motion } from 'framer-motion';

interface ProgressBarProps {
  total: number;
  current: number;
}

export function ProgressBar({ total, current }: ProgressBarProps) {
  return (
    <div className="flex gap-1">
      {Array.from({ length: total }).map((_, index) => (
        <div
          key={index}
          className="flex-1 h-1 bg-white/20 rounded-full overflow-hidden"
        >
          {index === current && (
            <motion.div
              className="h-full bg-white"
              initial={{ width: '0%' }}
              animate={{ width: '100%' }}
              transition={{ duration: 5, ease: 'linear' }}
            />
          )}
          {index < current && (
            <div className="h-full bg-white" />
          )}
        </div>
      ))}
    </div>
  );
}