import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { spotifyService } from '../services/spotify';

export function SpotifyCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function handleCallback() {
      try {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        
        if (!code) {
          throw new Error('No authorization code received');
        }

        const data = await spotifyService.getAccessToken(code);
        
        // Store the tokens securely
        localStorage.setItem('spotify_access_token', data.access_token);
        localStorage.setItem('spotify_refresh_token', data.refresh_token);

        // Redirect to success page or dashboard
        navigate('/dashboard');
      } catch (error) {
        console.error('Authentication error:', error);
        // Redirect to error page or show error message
        navigate('/connect?error=authentication_failed');
      }
    }

    handleCallback();
  }, [location, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-4">Connecting to Spotify...</h2>
        {/* Add a loading spinner here if you want */}
      </div>
    </div>
  );
}